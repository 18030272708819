import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers([
  "sidebar",
  "overlay",
  "logo",
  "toggleButton",
  "nav",
  "navItem",
  "navItemLink",
  "subNav",
  "subNavItem",
  "subNavItemLink",
  "icon",
]);

const Sidebar = helpers.defineMultiStyleConfig({
  baseStyle: {
    sidebar: {
      boxShadow: "md",
      flexDirection: "column",
      py: 2,
      zIndex: "sidebar",
      w: "sidebarWidth",
      maxW: "sidebarWidth",
    },
    overlay: {
      zIndex: "sidebarOverlay",
      display: { md: "none" },
      background: { base: "rgba(0, 0, 0, 0.2)", md: "none" },
      backdropFilter: "blur(2px) grayscale(0.4)",
    },
    toggleButton: {
      // when the sidebar is open
      "&[aria-expanded=true]": {
        left: "sidebarWidth",
      },
      zIndex: "sidebarToggle",
      top: 0,
      p: 0,
      width: "40px",
      height: "40px",
      minHeight: "40px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0",
      position: "fixed",
      borderBottomRightRadius: "lg",
      boxShadow: "md",
      border: "0",
      color: "gray.200",
      background: "rgba(0, 0, 0, 0.85)",
      colorScheme: "blackAlpha",
      _hover: {
        background: "rgba(0, 0, 0, 1)",
      },
    },
    logo: {
      px: 10,
      py: 10,
      display: "flex",
      justifyContent: "center",
      "& img": {
        maxW: "180px",
        maxH: "60px",
      },
    },
    nav: {},
    navItemGroup: {
      alignItems: "center",
      display: "flex",
      height: "3rem",
      width: "100%",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      _hover: {
        cursor: "pointer",
      },
    },
    navItem: {
      alignItems: "center",
      display: "flex",
      height: "3rem",
      width: "100%",
      justifyContent: "space-between",
      px: 8,
      cursor: "pointer",
      color: "black",
      textTransform: "uppercase",
      fontWeight: "500",
      whiteSpace: "nowrap",
      "&[aria-current=true]": {
        background: "brand.sidebarActive",
        fontWeight: "medium",
      },
    },
    navItemLink: {
      "&[aria-current=page]": {},
      width: "100%",
      alignItems: "center",
      display: "flex",
    },
    subNav: {
      background: "gray.50",
    },
    subNavItem: {
      "&[aria-current=true]": {
        background: "brand.sidebarActive",
        fontWeight: "semibold",
      },
    },
    subNavItemLink: {
      "&[aria-current=page]": {},
      display: "block",
      width: "100%",
      background: "transparent",
      pb: "2",
      pt: "2",
      pl: "5rem",
      _hover: {
        textDecoration: "underline",
      },
    },
    icon: {
      mr: 3,
    },
  },

  variants: {
    debug: {
      sidebar: {
        bg: "red.50",
      },
      navItem: {
        bg: "red.100",
      },
      subNavItem: {
        bg: "red.200",
      },
      icon: {
        borderColors: "red.50",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
});
export default Sidebar;
