const Container = {
  baseStyle: {
    py: [4, 4, 8, 8, 8],
    px: [4, 4, 8, 8, 12],
  },
  variants: {
    page: {
      maxW: "container.default",
      ml: 0,
    },
    full: {
      maxW: "100%",
    },
  },
  defaultProps: {
    variant: "page",
    centerContent: false,
  },
};

export default Container;
