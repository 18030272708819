import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "semibold",
    textTransform: "uppercase",
    borderRadius: "base",
    lineHeight: "inherit",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  sizes: {
    sm: {
      fontSize: "sm",
      px: 8,
      py: 4,
    },
    md: {
      fontSize: "sm",
      px: 8,
      py: 4,
      h: 7,
    },
  },
  variants: {
    borderless: (props) => {
      return {
        ...props.theme.components.Button.variants.borderless,
        border: 0,
      };
    },
    solidBlue: (props) => {
      return {
        ...props.theme.components.Button.variants.solid,
        color: "primary.inverted",
        bg: "primary.base",
        border: "1px solid",
        borderColor: "primary.base",
        _hover: {
          bg: "primary.subtle",
          borderColor: "primary.subtle",
          _disabled: {
            bg: "primary.base",
          },
        },
      };
    },
    alert: {
      color: "white",
      bg: "red.600",
    },

    secondary: {
      color: "white",
      bg: "orange.400",
      borderRadius: "full",
    },
    orangePill: {
      color: "white",
      bg: "orange.400",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: "orange.500",
      },
    },

    outlinePill: {
      color: "white",
      bg: "transparent.20",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "white",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },

    navigationButton: {
      color: "navigationButton.color",
      background: "navigationButton.background",
      hoverBackground: "navigationButton.hoverBackground",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "navigationButton.borderColor",
      textTransform: "none",
      _active: {
        bg: "navigationButton._hoverBackground",
      },
    },
    inActiveNavigationButton: {
      color: "inActiveNavigationButton.color",
      background: "inActiveNavigationButton.background",
      hoverBackground: "inActiveNavigationButton.hoverBackground",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "inActiveNavigationButton.borderColor",
      textTransform: "none",
      _active: {
        bg: "inActiveNavigationButton._hoverBackground",
      },
    },

    splitOutlinePill: {
      color: "white",
      bg: "transparent.20",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "orange.500",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },

    borderlessGray: {
      color: "gray.100",
      _hover: {
        background: "transparent",
      },
      _active: {
        color: "gray.600",
      },
    },

    outlineBlue: {
      border: "1px",
      borderColor: "primary.dark",
      color: "blue.800",
      _hover: {
        background: "transparent",
      },
      _active: {
        color: "blue.600",
      },
    },
    outline: (theme) => {
      return {
        ...theme?.theme.components.Button.variants.outline,
        border: "1px",
        borderColor: "outline.borderColor",
        color: "outline.color",
        _hover: {
          color: "outline.hoverColor",
        },
      };
    },
  },
  defaultProps: {
    size: "md",
    height: "7",
  },
};

export default Button;
