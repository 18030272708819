import { ApolloClient, gql, useQuery } from "@apollo/client";
import { Select, useTheme, Text, Flex } from "@chakra-ui/react";
import { GetThemeSelectionConfigQuery, ThemeName } from "apollo/generatedTypes";

const GET_THEME_SELECTION_CONFIG = gql`
  query getThemeSelectionConfig {
    getThemeSelectionConfig {
      shouldShowThemeSelection
    }
    getCompany {
      themeName
    }
  }
`;

const ThemeSelectDropdown = ({
  handleTheme,
  apolloClient,
}: {
  handleTheme: (theme: ThemeName) => void;
  apolloClient: ApolloClient<any>;
}) => {
  const theme = useTheme();

  const showThemeSelectionResponse = useQuery<GetThemeSelectionConfigQuery>(
    GET_THEME_SELECTION_CONFIG,
    {
      client: apolloClient,
    }
  );

  if (
    showThemeSelectionResponse?.data?.getThemeSelectionConfig
      ?.shouldShowThemeSelection === true
  ) {
    return (
      <Flex alignItems="center" gap="2">
        <Text whiteSpace="nowrap" color="gray.500">
          Theme
        </Text>
        <Select
          size="sm"
          onChange={(e) => {
            handleTheme(e.target.value as ThemeName);
          }}
          minW="150px"
          maxW="240px"
          defaultValue={theme?.config?.themeName}
          borderRadius="md"
        >
          <option value={ThemeName.Classic}>{ThemeName.Classic}</option>
          <option value={ThemeName.Allshares}>{ThemeName.Allshares}</option>
        </Select>
      </Flex>
    );
  }
  return null;
};

export default ThemeSelectDropdown;
