import { extendTheme } from "@chakra-ui/react";
import Button from "styles/components/button";
import Drawer from "styles/components/drawer";
import Modal from "styles/components/modal";
import Input from "styles/components/input";
import Badge from "styles/components/badge";
import EaiCard from "styles/components/eaicard";
import Table from "styles/components/table";
import Select from "styles/components/select";
import Checkbox from "./components/checkbox";
import WebContentContainer from "./components/webcontentcontainer";
import Banner from "./components/banner";
import Sidebar from "./components/sidebar";
import EAIListItem from "styles/components/eailistitem";
import { linearGradient, transparentize } from "polished";
import { ThemeName } from "apollo/generatedTypes";
import Footer from "./components/footer";
import Container from "./components/container";
import WelcomeBanner from "./components/welcomeBanner";

const defaultPalette = extendTheme().colors;

const palette = {
  ...defaultPalette,
  blue: {
    50: "#E5F4FF",
    100: "#B8E0FF",
    200: "#8ACDFF",
    300: "#5CB9FF",
    400: "#2EA5FF",
    500: "#0092FF",
    600: "#0075CC",
    700: "#005799",
    800: "#003C69",
    900: "#001D33",
  },
  green: {
    50: "#e9f9e6",
    100: "#cce8c6",
    200: "#acd7a5",
    300: "#8dc783",
    400: "#6eb761",
    500: "#549e48",
    600: "#407b37",
    700: "#2d5826",
    800: "#193615",
    900: "#011400",
  },
  orange: {
    50: "#ffefdd",
    100: "#fed3b2",
    200: "#f9b786",
    300: "#f49c57",
    400: "#f07f28",
    500: "#d7660f",
    600: "#a84f0a",
    700: "#783805",
    800: "#4a2100",
    900: "#1f0800",
  },
  pink: {
    50: "#FBE9F0",
    100: "#F4C2D5",
    200: "#ED9CBA",
    300: "#E6759E",
    400: "#DF4E83",
    500: "#D82768",
    600: "#AD1F53",
    700: "#82173F",
    800: "#56102A",
    900: "#2B0815",
  },
  violet: {
    50: "#f5eefa",
    100: "#dbcee3",
    200: "#c1aecf",
    300: "#a98ebc",
    400: "#916da8",
    500: "#775490",
    600: "#5c4170",
    700: "#422f50",
    800: "#281c31",
    900: "#0f0814",
  },
  defaultBlack: "#1B1B1B",
  defaultWhite: "#fff",
  defaultLightBlue: "#DFE9EE",
  defaultOrange: "#F07E2660",
  defaultViolet: "#422f50",
};

const colors = {
  ...palette,
  transparent: {
    20: "#00000033",
  },

  primary: {
    dark: palette["blue"][800],
  },

  link: palette["blue"][800],
  inputBackground: palette["whiteAlpha"][900],
  black: palette["defaultBlack"],
  white: palette["defaultWhite"],
  text: palette["defaultBlack"],
  background: palette["defaultWhite"],
  backgroundColors: {
    lightBlue: palette["defaultLightBlue"],
  },

  brand: {
    highlight: palette["blue"][500],
    selected: palette["blue"][600],
    color: palette["blue"][800],
    background: palette["gray"][100],
    secondaryBackground: palette["defaultOrange"],
    tertiaryBackground: palette["violet"][50],
    blueHighlight: "#E6EDF1",
    blueSelected: "#e6eef1",
    blue: "#4B6E83",
    blueBackground: "#E6EDF1",
    orangeBackground: "#F07E2660",
    violetBackground: "#D6C8E060",
  },

  reportColors: {
    account: palette["blue"][50],
    transaction: palette["violet"][50],
    savings: palette["orange"][50],
    income: palette["defaultWhite"],
    participantAccount: palette["blue"][100],
  },
  timeLineChartColors: {
    yAxis: palette["blue"][50],
  },
  timelinePeriodColors: {
    boardSetsEarningCriteria: palette["blue"][800],
    performancePeriod: palette["blue"][300],
    optionVestingPeriod: palette["blue"][200],
    ownershipRequirement: palette["orange"][400],
    sharePurchases: palette["orange"][100],
    measureOfAchievement: palette["pink"][500],
    optionDelivery: palette["green"][300],
    savings: palette["green"][100],
    vestingPeriodRequirement: palette["green"][500],
    subscriptionPeriod: palette["green"][200],
    sharePurchasesEssp: palette["green"][200],
    shareDelivery: palette["violet"][500],
    optionExpirationDate: palette["violet"][400],
    dividendAdjustment: palette["violet"][200],
  },
  sharePriceGraphColors: {
    grid: palette["blue"][50],
  },
  sidebarColors: {
    icon: "#08375C",
  },
  corporateActionTypeColors: {
    bonusIssue: palette["orange"][50],
    bonusShare: palette["blue"][300],
    changeOfTradecode: palette["blue"][200],
    combination: palette["blue"][100],
    dividend: palette["orange"][100],
    capitalReturn: palette["blue"][50],
    shareDividend: palette["defaultWhite"],
    spinOff: palette["green"][100],
    exchange: palette["blue"][200],
    rightDistribution: palette["green"][200],
    subscription: palette["green"][300],
    merger: palette["violet"][500],
    tenderOffer: palette["violet"][400],
    reverseSplit: palette["violet"][200],
    split: palette["blue"][50],
    salesOfSecurity: palette["violet"][50],
    vacuuming: palette["orange"][100],
  },
  personnelFundGraphColors: {
    restricted: palette["blue"][800],
    withdrawable: palette.orange[400],
    toBePaid: palette.red[400],
    newInvestments: palette.green[400],
    newTransfersWithdrawable: palette.blue[400],
    marketValue: palette.violet[400],
  },
  badgeColors: {
    bluePill: {
      color: "#4B6E83",
      background: "#E6EDF1",
    },
    greenPill: {
      color: "#428a33",
      background: "#E6F8E3",
    },
    violetPill: {
      color: "#8d669f",
      background: "#F2EDF5",
    },
    redPill: {
      color: "#AB5E78",
      background: "#F2D3DE",
    },
    yellowPill: {
      color: "#807A2A",
      background: "#FAF7CA",
    },
  },
  recentActionsColors: {
    headerBackground: palette["blue"][100],
    background: palette["blue"][50],
  },
  custodyAccountColors: {
    portfolio: palette["blue"][200],
    esspPortfolio: palette["violet"][200],
    accountRow: palette["blue"][50],
  },
  savingsColors: {
    footer: palette["blue"][50],
  },

  graph: {
    blue: "#003C69",
    orange: "#F07E26",
    violet: "#AD91C1",
    green: "#58A54B",
    lightOrange: "#f9b786",
  },
  dateRangeBadge: {
    font: palette["blue"][800],
    border: palette["blue"][300],
  },
  myDetails: {
    cardHeader: palette["blue"][200],
    sidebarBackground: palette["defaultWhite"],
  },
  postLogin: {
    bluePrimary: "#ACC7D760",
    blueSecondary: "#E6EDF1",
    orangePrimary: "#F07E2660",
    orangeCircle: "#F07E26",
    greenText: "#58A54B",
    grayText: "#8DA3B0",
  },
  dashboardHeaders: {
    blue: "#D8E4EC",
    green: "#A8D49C",
    orange: "#F9C69E",
    orangeIcon: "#F07E26",
    violet: "#E8DCEC",
    yellow: "#FFDC9C",
  },
  dashboardCards: {
    blue: "#E8ECF4",
    green: "#E8FCE4",
    orange: "#FFF4EC",
    yellow: "#FFF4DC",
  },
  donutGraph: [
    palette["blue"][800],
    palette["orange"][400],
    palette["violet"][500],
    palette["green"][500],
    palette["pink"][500],
    palette["defaultWhite"],
    "#E6EDF1",
  ],
  shareRegister: {
    blueHeader: "#C0D4DC",
    blueBackground: "#E5EDF0",
    greenHeader: "#B0D4A4",
    greenBackground: "#E6F7E4",
  },
  translucentWhite: "rgba(255, 255, 255, 0.9)",
};

const theme = extendTheme({
  config: {
    cssVarPrefix: "eai",
    themeName: ThemeName.Classic,
  },
  global: {
    /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
    */
    ".js-focus-visible :focus:not([data-focus-visible-added])": {
      outline: "none",
      boxShadow: "none",
    },
  },

  fonts: {
    heading: "Avenir LT",
    body: "Avenir LT",
  },

  zIndices: {
    sidebar: 1301,
    sidebarOverlay: 1302,
    sidebarToggle: 1303,
  },

  semanticTokens: {
    sizes: {
      sidebarWidth: "300px",
      containerMaxW: "1920px",

      // Used to wrap the content of the page
      container: {
        default: "1680px",
        narrow: "1200px",
      },
    },
    space: {
      padding: {
        base: 4,
        lg: 8,
        xl: 12,
      },
      sidebarWidth: "300px",
      // Space between columns
      gutter: {
        default: 8,
      },
    },
    colors: {
      text: "defaultBlack",
      primary: {
        base: { default: "primary.dark" },
        subtle: { default: palette["blue"][600] },
        inverted: { default: "white" },
      },

      alert: {
        base: { default: palette["pink"][800] },
        subtle: { default: palette["pink"][400] },
        inverted: { default: palette["pink"][50] },
      },

      warning: {
        base: { default: palette["orange"][800] },
        subtle: { default: palette["orange"][400] },
        inverted: { default: palette["orange"][50] },
      },

      error: {
        base: { default: palette["red"][800] },
        subtle: { default: palette["red"][400] },
        inverted: { default: palette["red"][50] },
      },

      success: {
        base: {
          default: palette["green"][800],
        },
        subtle: palette["green"][400],
        inverted: palette["green"][50],
      },

      pending: {
        ...colors.orange,
        base: { default: colors.orange[400] },
        subtle: { default: colors.orange[100] },
        inverted: { default: "white" },
      },

      welcomeBanner: {
        background: palette["blue"][50],
        text: "defaultBlack",
      },
      footer: {
        background: "rgba(1, 23, 40, 0.8)",
        text: "white",
        link: "yellow.100",
        linkHover: "yellow.200",
      },
    },
  },
  colors: {
    ...colors,
    navbar: {
      background: "transparent",
      color: "white",
    },

    brand: {
      ...colors.brand,
      dashboardWelcomeBox: {
        background: transparentize(0.2, colors.blue[50]),
        color: "black",
      },
      heading1: "white",
    },
    secondary: {
      background: colors["blue"][50],
      color: "black",
      headerBackground: colors["gray"][100],
    },
    login: {
      background: "white",
      helpdeskBackground: linearGradient({
        colorStops: ["#D0DFE8", "#E0E9EE", "#CDDCE5"],
        toDirection: "to right",
      }).backgroundImage,
      helpdeskDisclaimerBackground: "blue.800",
    },
    input: {
      // background: palette["defaultLightBlue"],
      background: "white",
      border: palette["defaultLightBlue"],
      color: "black",
      hoverBackground: colors["gray"][50],
    },
    alert: {
      background: colors["orange"][50],
      color: "black",
      headerBackground: colors["orange"][100],
    },
    graph: {
      background: "white",
      color: "black",
      headerBackground: "white",
    },
    neutral: {
      background: colors["blue"][50],
      color: "black",
      headerBackground: "#ACC7d7",
      headerColor: colors["blue"][800],
    },
    success: {
      background: colors["green"][50],
      color: "black",
      headerBackground: colors["green"][100],
    },
    navigationButton: {
      color: "white",
      background: colors["orange"][400],
      hoverBackground: colors["orange"][500],
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: colors["orange"][600],
      },
    },

    inActiveNavigationButton: {
      color: "white",
      background: "transparent.20",
      hoverBackground: "orange.500",
      borderColor: "white",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },
    table: {
      subHeader: palette["blue"][50],
      evenBackground: palette["gray"][50],
      oddBackground: palette["white"],
      rowBorder: palette["blue"][50],
      footerBorder: palette["blue"][800],
      hover: palette["blue"][50],
      header: "#ACC7D7",
      selectedBackground: colors["blue"][50],
      alertBackground: colors["blue"][200],
      activeBackground: colors["gray"][300],
      summaryBackground: colors["gray"][100],
      summaryColor: colors["black"],
    },
  },
  components: {
    Badge,
    Banner,
    Button,
    Checkbox,
    Container,
    Drawer,
    EaiCard,
    EAIListItem,
    Footer,
    Input,
    Modal,
    Select,
    Sidebar,
    Table,
    WelcomeBanner,
    WebContentContainer,
  },

  multiselect: {
    container: {
      border: 0,
      background: "transparent",
    },
    valueContainer: {
      background: "white",
      paddingLeft: 2,
      width: "100%",
      minHeight: "40px",
    },
    control: {
      border: "1px solid",
      borderRadius: 6,
      borderColor: "#eaeaea",
    },
    menu: {
      zIndex: 2,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
      mt: "2px",
    },
    dropdownIndicator: {
      background: "white",
    },
  },
});

export default theme;
