import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers([
  "wrapper",
  "container",
  "heading",
  "column",
  "divider",
  "logo",
]);

const Footer = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      width: "full",
      bg: "footer.background",
      color: "footer.text",
      overflow: "hidden",
      py: { base: 4, md: 8 },
      a: {
        color: "footer.link",
        _hover: {
          color: "footer.linkHover",
        },
      },
    },
    container: {},
    content: {},
    column: {},
    heading: {
      fontSize: "md",
      fontWeight: "semibold",
      mb: 4,
    },
    logo: {
      flexDir: "column",
      textAlign: { lg: "center" },
      order: 2,
      justifySelf: "flex-end",
      justifyContent: "center",
      "& img": {
        maxW: "240px",
        width: "100%",
        mb: 4,
      },
    },
    divider: {
      borderColor: "footer.text",
    },
  },
  defaultProps: {},
});

export default Footer;
